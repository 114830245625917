$(window).load(function() {
    htmlHeight = $('body').height();
    windowHeight = $(window).height();
    
    if(windowHeight > htmlHeight) {
        // document.getElementById("footer").style.height = "500px";"$(window).height() - $('body').height() + 100;"
        //  $('.footer').animate({'height':'40vh'},5000);
        $(".footer").animate({height: $(window).height() - $('body').height() + 170}, 200);
    }
});

$(document).ready(function() {
    var window_width = $(window).width();
    // console.log(window_width);
    
    /* Kontrolni promenna na sirku okna */
    var points_speed = 7;
    
    if(window_width < 1650 && window_width > 1400) {
        points_speed = 6;
    } else if(window_width < 1400) {
        points_speed = 5;
    }
    
    /*if (window_width < 1650){
        points_speed = 4;
    }*/
    
    /* Pocitadla */
    $('.statistics div').each(function(index) {
        // $(this).fadeTo("slow",0);
    });
    
    
    //alert(htmlHeight);
    //alert(windowHeight);
    
    
    //$('.number').counterUp();
    $('.statistics .number').each(function(index) {
        
        
        //$(this).delay(800*index).fadeTo("slow",1);
        
        $(this).delay(850 * index).prop('Counter', 0).animate({
            
            opacity: 1,
            Counter: $(this).text()
            
        }, {
            duration: 2000,
            
            easing: 'swing',
            step: function(now) {
                $(this).text(Math.ceil(now));
            }
        });
        
        //$(this).fadeTo("slow",1);
    });
    
    /* $(".bg").mouseenter(function(event){
          $('.points-section').css('right',  '-300px');

      });*/
    
    
    $(".bg").mousemove(function(event) {
        
        var containerWidth = $(this).innerWidth(),
            containerHeight = $(this).innerHeight(),
            containerOffsetLeft = $(this).offset().left,
            containerOffsetTop = $(this).offset().top,
            mousePositionX = ((event.pageX - containerOffsetLeft) / containerWidth) * 100,
            mousePositionY = ((event.pageY - containerOffsetTop) / containerHeight) * 100,
            mousePositionXPoint = ((event.pageX - containerOffsetLeft) / containerWidth) * points_speed
        //alert(mousePositionX);
        
        $(this).css('background-position', mousePositionX + '%', 'transition');
        $('.points-section').css('right', mousePositionXPoint + '%');
        
    });
    
    
    var bg = $(".bg");
    
    
    $('.statistics .text').each(function(index) {
        
        $(this).delay(900 * index).fadeTo("slow", 1);
        
    });
    
    for(i = 0; i < 150; i++) {
        $(function() {
            
            (function pulse() {
                $('.points-section div').each(function(index) {
                    $(this).delay(6000 * index).delay(8000).fadeTo("slow", 0.3).delay(550).fadeTo('slow', 1);
                });
                
            })();
            
        });
    }
    
    
    $.nette.ext('spinner', {
        init: function() {
            this.spinner = this.createSpinner();
            this.spinner.appendTo('body');
        },
        start: function() {
            this.counter++;
            if(this.counter === 1) {
                this.spinner.show(this.speed);
            }
        },
        complete: function() {
            this.counter--;
            if(this.counter <= 0) {
                this.spinner.hide(this.speed);
            }
        }
    }, {
        createSpinner: function() {
            return $('<div>', {
                id: 'ajax-spinner',
                css: {
                    display: 'none'
                }
            });
        },
        spinner: null,
        speed: undefined,
        counter: 0
    });
    $.nette.init();
    
    
    $(function() {
        $('.matchHeightByRow').matchHeight({
            byRow: true,
            property: 'height'
        });
    });
    
    if($(".slick-carousel")[0]) {
        $(".slick-carousel").slick({
            dots: false,
            infinite: true,
            slidesToShow: 1,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 3000,
            fade: true,
            cssEase: 'linear',
            draggable: false,
            touchMove: false,
            accessibility: false,
            focusOnSelect: false
        });
    }
    
    // univerzalni scroll target
    $(".jump-to").each(function() {
        var where = $(this).data("where");
        $(this).on("click", function(event) {
            event.preventDefault();
            $.scrollTo(where, 1500, {easing: "easeOutExpo", interrupt: true});
        });
    });
    
    $(".box-sector-collapse").on('click', function() {
        var arrow = $(this).closest('.box-sector-arrow');
        
        if($(arrow).hasClass('box-sector-arrow-close')) {
            $(arrow).removeClass('box-sector-arrow-close');
        } else {
            $(arrow).addClass('box-sector-arrow-close');
        }
    });
    
    $(".product").click(function() {
        var id = $(this).data("id");
        var activeProducts = $(".product.active");
        $(this).addClass('active');
        $('.products-info-message').show();
        $("#" + id).show();
        
        if(typeof activeProducts === 'undefined') {
            $('.products-info-message').hide();
        }
    });
    
    $(".remove").click(function() {
        var id = $(this).data("id");
        var activeProducts = $(".product.active");
        
        for(var i = 0; i < activeProducts.length; i++) {
            if($(activeProducts[i]).data('id') === id) {
                $(activeProducts[i]).removeClass('active');
            }
        }
        if(activeProducts.length === 1) {
            $('.products-info-message').hide();
        }
        $("#" + id).hide();
        $("#frm-demand-" + id).val("");
    });
    
    (function checkProductActivation() {
        if($('.product.active')[0]) {
            var activeProducts = $(".product.active");
            
            if(typeof activeProducts !== 'undefined') {
                $('.products-info-message').show();
            }
        }
    })();
    
    // if(Cookies.get("enetexPopup") === "true") {
    //     $("#enetexPopup").modal("hide");
    // } else {
    //     $("#enetexPopup").modal("show");
    //     Cookies.set("enetexPopup", "true", { expires: 5 });
    // }
});
