$(document).ready(function(){

    $(".fancybox").fancybox({
        padding: 3
    });

    function closeModal() {
        $("body").removeClass("modal-open");
        $(".modal-backdrop.in").removeClass("fadeInModal").addClass("fadeOutModal");
        $(".modal.modal-alert").removeClass("in").addClass("out");
        setTimeout(function(){
            $(".modal-backdrop.in").remove();
            $(".modal.modal-alert").remove();
        }, 600);
    }

    if ($(".alert")[0]) {
        setTimeout(function(){
            closeModal();
        }, 5000);
    }

    $(".modal.modal-alert, .alert .close").on("click", function() {
        closeModal();
    });

});
